import { bindable, inject } from 'aurelia-framework';
import { AppContainer }     from 'resources/services/app-container';

@inject(AppContainer)
export class RecursiveMenu {

    @bindable menu;
    @bindable parentName;
    @bindable disabled;
    @bindable permissionActions;
    @bindable permissions;
    @bindable permissionIdByResourcePermissionsAndAction;

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        this.appContainer = appContainer;
    }

    get fullName() {
        return this.parentName ? `${this.parentName} » ${this.menu.name}` : this.menu.name;
    }
}
